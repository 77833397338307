<template>
  <div>
    <div @click="openPopupCharged()">
      <vs-button v-if="cellValue" size="small" color="success" type="border">Cobrado</vs-button>
      <vs-button v-else size="small" color="danger" type="border">No cobrado</vs-button>
    </div>
    <vs-popup :title="title" :active.sync="popupActive">
        <p> Marcar como
          <span v-if="cellValue" class="text-dark uppercase">no cobrado</span>
          <span v-else class="text-dark uppercase">cobrado</span>
        </p>
        <vs-button v-can="'change_panel_charged_campaign'" @click="changeStateCharged()" color="primary" type="filled" class="float-right">Cambiar</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import appConfig from '@/../appConfig.js'

export default Vue.extend({
  data () {
    return {
      rowValue: null,
      cellValue: null,
      popupActive: false,
      title: null
    }
  },
  beforeMount () {
    this.cellValue = this.getValueToDisplay(this.params)
    this.rowValue = this.params.data
    this.title = `Campaña ID: ${this.rowValue.id}`
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh (params) {
      this.cellValue = this.getValueToDisplay(params)
    },

    openPopupCharged () {
      this.popupActive = !this.popupActive
    },

    getValueToDisplay (params) {
      return params.value
    },

    changeStateCharged () {
      this.$http.post(`${appConfig.apiUrl}/panel/campaigns/${this.rowValue.id}/charged`, {
        params: {}
      })
        .then((response) => {
          this.cellValue = response.data.charged

          this.$vs.notify({
            title: this.title,
            text: `${this.cellValue ? 'COBRADO' : 'NO COBRADO'}`,
            color: 'primary'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            color: 'danger'
          })
        })
        .then(() => {
          // always executed
          this.popupActive = !this.popupActive
        })
    }
  }
})
</script>