<template>
    <div class="custom-tooltip vs-tooltip vs-tooltip-bottom">
        <b>{{ profitPercentage }}%</b>
    </div>
</template>

<style>
    .custom-tooltip {
        position: absolute;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 1s;
    }

    .custom-tooltip.ag-tooltip-hiding {
        opacity: 0;
    }

    .custom-tooltip p {
        margin: 5px;
        white-space: nowrap;
    }

    .custom-tooltip p:first-of-type {
        font-weight: bold;
    }
</style>


<script>
import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      profitPercentage: null
    }
  },
  beforeMount () {
    this.profitPercentage = this.params.value.profitPercentage
  }
})
</script>