var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "custom-tooltip vs-tooltip vs-tooltip-bottom" },
    [_c("b", [_vm._v(_vm._s(_vm.profitPercentage) + "%")])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }